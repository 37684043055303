import { BrowserRouter as Router, Link, Route, Switch } from "react-router-dom"
import Index from "./pages"
import NotFound from "./pages/404"
import Settings from "./pages/settings"

function App() {
  return (
    <Router>
      <nav>
        <Link to="/">Home</Link>
        <Link to="/settings">Settings</Link>
      </nav>
      <Switch>
        <Route exact path="/">
          <Index />
        </Route>
        <Route path="/settings">
          <Settings />
        </Route>
        <Route>
          <NotFound />
        </Route>
      </Switch>
    </Router>
  );
}

export default App;
